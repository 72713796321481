import axios from 'axios'
import store from '@/store'
import _ from 'lodash'
import router from '@/router'
import { initialVuexStore } from '@/assets/javascripts/setting.js'

/************************************
 * IF HOST IS '/api' THEN NGINX WILL
 * REDIRECT TO '/api/leishanBms'
 ************************************/
const API_HOST = '/api'
const KM_API = '/km_api/v2'
const clientId = 'bms'
const LAB_HOST = '/ls_lab_api'
const KM_HOST = '/_km'
const CORE_HOST = '/core_root_api'

/************************************
 * THESE ARE TRANSFORM OUTPUT FUNCTION
 ************************************/

const transformOutputType = (_payload) => {
    const payload = _.cloneDeep(_payload)
    const toNumberList = ['manCode', 'mType', 'bgCode', 'tCode']
    toNumberList.forEach((key) => {
        if (payload[key]) payload[key] = Number(payload[key])
    })
    return payload
}

/************************************
 * THESE ARE SSO APIS BELOW
 ************************************/

const getTokenAPI = (payload) => {
    return axios.post('/_sso/token', payload)
}
const headers = {
    get Authorization() {
        return store.getters.token
    },
    'Client-ID': clientId,
    'Content-Type': 'application/json'
}

const getUserInfoAPI = () => {
    return axios.get(`${API_HOST}/memberInfo`)
}

const refreshTokenAPI = () => {
    console.log('refreshing..')
    const refreshToken = store.getters.refreshToken
    var data = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${clientId}`
    return axios.post('/_sso/token', data)
}

/************************************
 * THESE ARE Shared APIS BELOW
 ************************************/

const getAccountCodesAPI = () => {
    return axios.get(`${API_HOST}/codes`)
}

const getPageColumnConfigAPI = (path) => {
    return axios.get(`${API_HOST}/settings/pages/${path}`)
}

const savePageColumnConfigAPI = (path, payload) => {
    return axios.put(`${API_HOST}/settings/pages${path}`, payload)
}

const postUserActionAPI = (payload) => {
    return axios.post(`${CORE_HOST}/log/bms/user/action`, payload)
}

/************************************
 * THESE ARE COMMISSION APIS BELOW
 ************************************/

const getCommissionMTypeAPI = () => {
    return axios.get(`${API_HOST}/commission/mTypes`)
}

const getCommissionCodesAPI = () => {
    return axios.get(`${API_HOST}/commission/codes`)
}

const getCommissionHistoryAPI = (payload) => {
    return axios.post(`${API_HOST}/commission/history`, payload)
}

const getCommissionOrgHistoryAPI = (payload) => {
    return axios.post(`${API_HOST}/commission/orgHistory`, payload)
}

const getCommissionPreClosingAPI = (payload) => {
    return axios.post(`${API_HOST}/commission/preClosing`, payload)
}

const closingCommissionAPI = (mTypeCode) => {
    return axios.get(`${API_HOST}/commission/close?mType=${mTypeCode}`)
}

const openingCommissionAPI = (mTypeCode) => {
    return axios.get(`${API_HOST}/commission/open?mType=${mTypeCode}`)
}

const setCommissionVisibleAPI = (isVisible, mTypeCode) => {
    const route = isVisible
        ? `${API_HOST}/commission/changeVisibilityTrueForSales?mType=${mTypeCode}`
        : `${API_HOST}/commission/changeVisibilityFalseForSales?mType=${mTypeCode}`
    return axios.get(route)
}

const downloadSalesZipAPI = (payload) => {
    payload.receiveInfos = payload.receiveInfos.map((infos) =>
        transformOutputType(infos)
    )
    return axios.post(`${API_HOST}/commission/downloadSalesZip`, payload)
}

const downloadAdminZipAPI = (payload) => {
    return axios.post(`${API_HOST}/commission/downloadAdminZip`, payload)
}

const sendZipPasswordToAdminAPI = () => {
    return axios.get(`${API_HOST}/commission/sendZipPasswordToAdmin`)
}

const syncPksListAPI = () => {
    return axios.get(`${API_HOST}/commission/syncList`)
}

const generateCommissionAPI = (payload) => {
    return axios.post(`${API_HOST}/commission/generateCommission`, payload)
}

const syncPksAPI = (mTypeCode) => {
    return axios.get(`${API_HOST}/commission/sync?mType=${mTypeCode}`, {
        timeout: 1000 * 60 * 5
    })
}

const canSyncPksAPI = () => {
    return axios.get(`${API_HOST}/commission/canSync`)
}

/************************************
 * THESE ARE Organization APIS BELOW
 ************************************/

const getOrganizationListAPI = (payload) => {
    return axios.post(`${API_HOST}/organization/list`, payload)
}

const getOrgChangeListAPI = (payload) => {
    return axios.post(`${API_HOST}/organization/changeList`, payload)
}

const getEditDropdownCodesAPI = () => {
    return axios.get(`${API_HOST}/organization/codes`)
}

const editOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/organization/edit`, payload)
}

/************************************
 * THESE ARE PERFORMANCE APIS BELOW
 ************************************/

const getPerformanceCodesAPI = () => {
    return axios.get(`${API_HOST}/performance/codes`)
}

const getPerformanceListAPI = (payload) => {
    return axios.post(`${API_HOST}/performance/list`, payload)
}

const getPerformancePersonalDetailsAPI = (payload) => {
    return axios.post(`${API_HOST}/performance/personalDetails`, payload)
}

const getPopularProductsAPI = () => {
    return axios.get(`${API_HOST}/popularProducts`)
}

/************************************
 * THESE ARE MANPOWER APIS BELOW
 ************************************/

const getManpowerCodesAPI = () => {
    return axios.get(`${API_HOST}/organization/codes`)
}

const getManpowerListAPI = (payload) => {
    return axios.post(`${API_HOST}/organization/manpower`, payload)
}

/************************************
 * THESE ARE Report APIS BELOW
 ************************************/
const getReportAnnualCodesAPI = () => {
    return axios.get(`${API_HOST}/report/orgPerformance/codes`)
}
const getReportIntervalCodesAPI = () =>
    axios.get(`${API_HOST}/report/general/org/performance/codes`)

const getReportListAnnualBranchAPI = (payload) => {
    return axios.post(`${API_HOST}/report/orgPerformanceBranch`, payload)
}

const getReportListAnnualPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/report/orgPerformancePersonal`, payload)
}

const getReportListDetailAPI = (payload) => {
    return axios.post(`${API_HOST}/report/unitPerformanceList`, payload)
}

const getReportListDailyAPI = (payload) => {
    return axios.post(`${API_HOST}/report/quickReport`, payload)
}

const getReportContestCodesAPI = () => {
    return axios.get(`${API_HOST}/report/contest/codes`)
}

const getReportContestPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/report/contest/personal`, payload)
}

const getReportContestOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/report/contest/org`, payload)
}

const getReportListIntervalAPI = (payload) => {
    return axios.post(`${API_HOST}/report/general/org/performance`, payload)
}

/************************************
 * THESE ARE Report APIS BELOW
 ************************************/

const getAwardsCodesAPI = () => {
    return axios.get(`${API_HOST}/award/codes`)
}

const getAwardsHgaPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/award/hga/personal`, payload)
}

const getAwardsHgaOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/award/hga/org`, payload)
}

const getAwardsLgaPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/award/lga/personal`, payload)
}

const getAwardsLgaOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/award/lga/org`, payload)
}

const getAwardsMdrtPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/award/mdrt/personal`, payload)
}

const getAwardsMdrtOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/award/mdrt/org`, payload)
}

const getAwardsIdaPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/award/ida/personal`, payload)
}
const getAwardsIdaOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/award/ida/org`, payload)
}

const getAwardsGoldPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/award/ifpa/personal`, payload)
}
const getAwardsGoldOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/award/ifpa/org`, payload)
}

const getAwardsApfPersonalAPI = (payload) => {
    return axios.post(`${API_HOST}/award/apfinsa/personal`, payload)
}
const getAwardsApfOrgAPI = (payload) => {
    return axios.post(`${API_HOST}/award/apfinsa/org`, payload)
}

const getAwardsDetailAPI = (payload) => {
    let data = {
        performanceTotal: {
            self: Array(8).fill({
                insNo: '555666777888',
                supplier: '友邦人壽',
                payer: '林小磊',
                receiveDate: '2023-02-24',
                effectDate: '2023-02-24',
                contestFyb: 55666778
            }),
            underline: Array(8).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                salesName: '林小磊',
                title: '總監(C)',
                contestFyb: 1234567
            })
        },
        hga: {
            self: Array(1).fill({
                bgName: '騰躍營運處',
                salesName: '林小磊',
                level: '醫療守護天使'
            }),
            underline: Array(8).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                salesName: '林小磊',
                title: '總監(C)',
                insuredCount: 5,
                level: '晶鑽守護天使'
            })
        },
        lga: {
            self: Array(1).fill({
                bgName: '騰躍營運處',
                salesName: '林小磊',
                level: '玉璽幸福守護家'
            }),
            underline: Array(7).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                salesName: '林小磊',
                title: '總監(C)',
                contestInsAmount: 1234567,
                level: '玉璽幸福守護家'
            })
        },
        yearlyManpower: {
            self: Array(6).fill({
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                registerDate: '2022-02-20',
                contestFyb: 1234567
            }),
            underline: Array(6).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                recommendPerson: '林小磊',
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                contestFyb: 1234567
            })
        },
        yearlyManpowerCmDivideCa: {
            self: Array(6).fill({
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                registerDate: '2022-02-20',
                contestFyb: 1234567
            }),
            underline: Array(6).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                recommendPerson: '林小磊',
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                contestFyb: 1234567
            })
        },
        selfSell: {
            self: Array(8).fill({
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                registerDate: '2022-02-20',
                contestFyb: 1234567
            })
        },
        selfManager: {
            self: Array(8).fill({
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                registerDate: '2022-02-20',
                contestFyb: 1234567
            })
        },
        selfManagement: {
            self: Array(8).fill({
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                registerDate: '2022-02-20',
                contestFyb: 1234567
            })
        },
        selfOperate: {
            self: Array(8).fill({
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                registerDate: '2022-02-20',
                contestFyb: 1234567
            })
        },
        underlineSell: {
            underline: Array(8).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                recommendPerson: '林小磊',
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                contestFyb: 1234567
            })
        },
        underlineManager: {
            underline: Array(8).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                recommendPerson: '林小磊',
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                contestFyb: 1234567
            })
        },
        underlineManagement: {
            underline: Array(8).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                recommendPerson: '林小磊',
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                contestFyb: 1234567
            })
        },
        underlineOperate: {
            underline: Array(8).fill({
                bgName: '騰躍營運處',
                orgBmName: '黃騰躍',
                recommendPerson: '林小磊',
                recommendedPerson: '林小磊',
                recommendedPersonTitle: '總監(C)',
                contestFyb: 1234567
            })
        },
        promotionOne: {
            self: Array(3).fill({
                effectDate: '2023-02-24',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        promotionManager: {
            self: Array(4).fill({
                effectDate: '2023-02-24',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        promotionManagement: {
            self: Array(5).fill({
                effectDate: '2023-02-24',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        promotionOperate: {
            self: Array(6).fill({
                effectDate: '2023-02-24',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        recommendManpower: {
            self: Array(9).fill({
                bgName: '騰躍營運處',
                orgBmName: '林小磊',
                salesName: '王大明',
                registerDate: '2023-02-24',
                contestFyb: 1234567
            })
        },
        underlinePromotionManager: {
            underline: Array(8).fill({
                effectDate: '2023-02-24',
                salesName: '王大明',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        underlineCaPromotionManager: {
            underline: Array(8).fill({
                effectDate: '2023-02-24',
                salesName: '王大明',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        underlinePromotionManagement: {
            underline: Array(8).fill({
                effectDate: '2023-02-24',
                salesName: '王大明',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        underlinePromotionOperate: {
            underline: Array(8).fill({
                effectDate: '2023-02-24',
                salesName: '王大明',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        underlinePerPromotion: {
            underline: Array(8).fill({
                effectDate: '2023-02-24',
                salesName: '王大明',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        },
        underlinePromotionOne: {
            underline: Array(8).fill({
                effectDate: '2023-02-24',
                salesName: '王大明',
                title: '副理',
                chgDate: '2023-02-24',
                score: 500
            })
        }
    }
    return { data, payload }
}

/************************************
 * THESE ARE TIA APIS BELOW
 ************************************/

const getTiaInsuranceListAPI = (
    payload = {
        jobType: null,
        statusId: null,
        supplierCode: null,
        customerName: null,
        isDue: null
    }
) => {
    return axios.post(`${API_HOST}/insuranceService/searchInfoList`, payload)
}

const getTiaInsuranceDetailAPI = (id, manCode) => {
    return axios.get(
        `${API_HOST}/insuranceService/searchInfoDetail/${id}${
            manCode ? '/' + manCode : ''
        }`
    )
}

const editTiaInsuranceDetailAPI = (payload) => {
    return axios.put(`${API_HOST}/insuranceService/editInfo`, payload)
}

const completeTiaInsuranceNoticeAPI = (payload) => {
    return axios.post(`${API_HOST}/insuranceService/serviceBatchCheck`, payload)
}

const getTiaCustomerListAPI = (payload) => {
    // const payload = {
    //     customerName: null,
    //     isDue: 0,
    //     upcomingBirthday: null,
    //     insType: null,
    //     insAge: null
    // }
    return axios.post(`${API_HOST}/customerInfoService/searchList`, payload)
}
const getTiaCustomerDetailListAPI = (cusNo) => {
    return axios.get(`${API_HOST}/customerInfoService/searchInfo/${cusNo}`)
}
const getCustomerSearchCodesAPI = () => {
    return axios.get(`${API_HOST}/customerInfoService/codes`)
}

const putCustomerInfoApi = (payload) => {
    return axios.put(`${API_HOST}/customerInfoService/editInfo`, payload)
}

const getTiaNotificationListAPI = (payload) => {
    return axios.post(`${API_HOST}/insuranceService/notifyList`, payload, {
        timeout: 1000 * 60 * 5
    })
}

const getNotificationCodesAPI = () =>
    axios.get(`${API_HOST}/insuranceService/notifyList/codes`)

const getTiaSupplierSyncListAPI = (route, payload) => {
    return axios.post(`/core_root_api/policy/${route}`, payload)
}

const getTiaInsuranceCommissionDetailAPI = (id, manCode) => {
    return axios.get(
        `${API_HOST}/insuranceService/commissionDetail/${id}${
            manCode ? '/' + manCode : ''
        }`
    )
}

const getTiaInsuranceSettingAPI = (manCode) => {
    return axios.get(`${API_HOST}/settings/members/${manCode}`)
}

const editTiaInsuranceSettingsAPI = (manCode, payload) => {
    return axios.put(`${API_HOST}/settings/members/${manCode}`, payload)
}

const downloadPropertyExcelAPI = (payload) => {
    return axios.post(`${API_HOST}/insuranceService/downloadExcel`, payload)
}

const uploadPropertyExcelAPI = (formData) => {
    return axios.post(`${API_HOST}/insuranceService/uploadExcel`, formData)
}

/************************************
 * THESE ARE DASHBOARD APIS BELOW
 ************************************/
const getDashboardLicenseAPI = () => {
    return axios.get(`${API_HOST}/dashboard/certificate`)
}

const getDashboardNotificationAPI = () => {
    return axios.get(`${API_HOST}/dashboard/notifyStatistics`)
}

const getDashboardCustomerAgeAPI = (
    payload = {
        birthdayRemain: 0,
        insBirthdayRemain: 0
    }
) => {
    return axios.post(`${API_HOST}/dashboard/customerBirthday`, payload)
}

const getDashboardAwardQuickLinkAPI = () => {
    return axios.get(`${LAB_HOST}/award/hash_value`)
}

const getDashboardPersonalPerformanceAPI = () => {
    return axios.get(`${API_HOST}/dashboard/personalPerformance`)
}
const getDashboardContestAPI = () => {
    return axios.get(`${API_HOST}/dashboard/contest`)
}

const getDashboardOrgPerformanceAPI = () => {
    return axios.get(`${API_HOST}/dashboard/orgPerformance`)
}

const getDashboardTopTenProductionsAPI = () => {
    return axios.get(`${API_HOST}/dashboard/topTenProducts`)
}

const getDashboardDiscontinuedProdAPI = () => {
    return axios.get(`${KM_API}/ext/offSaleProducts`)
}
const getDashboardAnnouncementAPI = () => {
    return axios.get(`${KM_API}/ext/announcements`)
}

const getDashboardOrganizationManpowerAPI = () => {
    return axios.get(`${API_HOST}/dashboard/orgManpower`)
}

const getKMFileAPI = (url) => {
    const path = url.replace(process.env.VUE_APP_KM_URI, '')
    return axios.get(`${KM_HOST}/${path}`, { responseType: 'blob' })
}

/************************************
 * THESE ARE BACKSTAGE APIS BELOW
 ************************************/
const getBackstageProductFilesInfoAPI = () => {
    return axios.get(`${API_HOST}/backstage/products/files/infos`)
}
const getBackstageContestInfoAPI = () => {
    return axios.get(`${API_HOST}/backstage/contests/infos`)
    // return {
    //     contests: [
    //         {
    //             id: 5,
    //             name: '2023寰宇菁英會',
    //             scoreTitle: '點數',
    //             dateStart: '2023-03-01 00:00:00.000',
    //             dateEnd: '2023-06-01 00:00:00.000',
    //             scoreFybPerUnit: 200000,
    //             leaderScoreFybPerUnit: 200000,
    //             commitDateEnd: '2023-08-01 00:00:00.000',
    //             useScoreTitle: true,
    //             baseRate: 1,
    //             fwRate: 3,
    //             spRate: 0,
    //             mobileRate: 1.25,
    //             customRate: 1,
    //             year: '2023',
    //             groupId: '2',
    //             alias: '寰宇菁英會',
    //             isDisplay: '1',
    //             hasOrgMode: true,
    //             isCeoVisible: true,
    //             remoteRate: '1.30000',
    //             isVisible: '1',
    //             stylers: {
    //                 scoreTextColor: '#454D61',
    //                 backgroundColor: null,
    //                 titleTextColor: '#454D61',
    //                 titleBorderColor: '#FFFFFF',
    //                 dateLimitTextColor: '#454D61',
    //                 performanceTextColor: '#454D61',
    //                 scoreImage:
    //                     'https://feature-2.finext.app/pic/contest/5/scoreImage.png',
    //                 backgroundImage:
    //                     'https://feature-2.finext.app/pic/contest/5/backgroundImage.png',
    //                 linkButtonImage:
    //                     'https://feature-2.finext.app/pic/contest/5/linkButtonImage.png',
    //                 trophyImage:
    //                     'https://cdn-icons-png.flaticon.com/512/1017/1017466.png',
    //                 medalImage: ''
    //             },
    //             scoreFybRules: {
    //                 1: {
    //                     1: 100000,
    //                     9: 100000,
    //                     17: 100000,
    //                     25: 100000,
    //                     33: 100000,
    //                     41: 100000,
    //                     49: 100000,
    //                     57: 100000,
    //                     65: 100000,
    //                     73: 100000
    //                 },
    //                 2: {
    //                     1: 100000,
    //                     2: 100000,
    //                     3: 100000,
    //                     25: 100000,
    //                     26: 100000,
    //                     27: 100000,
    //                     49: 100000,
    //                     50: 100000,
    //                     51: 100000,
    //                     73: 100000,
    //                     74: 100000,
    //                     75: 100000,
    //                     97: 100000,
    //                     98: 100000,
    //                     99: 100000,
    //                     121: 100000,
    //                     122: 100000,
    //                     123: 100000,
    //                     145: 100000,
    //                     146: 100000,
    //                     147: 100000,
    //                     169: 100000,
    //                     170: 100000,
    //                     171: 100000,
    //                     193: 100000,
    //                     194: 100000,
    //                     195: 100000,
    //                     217: 100000,
    //                     218: 100000,
    //                     219: 100000
    //                 }
    //             }
    //         },
    //         {
    //             id: 4,
    //             name: 'IDA國際龍獎',
    //             scoreTitle: '單位',
    //             dateStart: '2023-01-01 00:00:00.000',
    //             dateEnd: '2024-01-01 00:00:00.000',
    //             scoreFybPerUnit: 99999999,
    //             leaderScoreFybPerUnit: 99999999,
    //             commitDateEnd: '2024-01-01 00:00:00.000',
    //             useScoreTitle: false,
    //             baseRate: 1,
    //             fwRate: 1,
    //             spRate: 1,
    //             mobileRate: 1,
    //             customRate: 1,
    //             year: '2023',
    //             groupId: '5',
    //             alias: 'IDA國際龍獎',
    //             isDisplay: '0',
    //             hasOrgMode: false,
    //             isCeoVisible: false,
    //             remoteRate: '1.00000',
    //             isVisible: '1',
    //             stylers: {
    //                 scoreTextColor: '#FFFFFF',
    //                 backgroundColor: null,
    //                 titleTextColor: '#FFFFFF',
    //                 titleBorderColor: '#F19537',
    //                 dateLimitTextColor: '#FFFFFF',
    //                 performanceTextColor: '#FFFFFF',
    //                 scoreImage:
    //                     'https://feature-2.finext.app/pic/contest/4/scoreImage.png',
    //                 backgroundImage:
    //                     'https://feature-2.finext.app/pic/contest/4/backgroundImage.png',
    //                 linkButtonImage:
    //                     'https://feature-2.finext.app/pic/contest/4/linkButtonImage.png'
    //             },
    //             scoreFybRules: null
    //         },
    //         {
    //             id: 3,
    //             name: '2022磊山高峰會',
    //             scoreTitle: '籌碼',
    //             dateStart: '2022-08-01 00:00:00.000',
    //             dateEnd: '2022-12-01 00:00:00.000',
    //             scoreFybPerUnit: 300000,
    //             leaderScoreFybPerUnit: 300000,
    //             commitDateEnd: '2023-03-01 00:00:00.000',
    //             useScoreTitle: true,
    //             baseRate: 1,
    //             fwRate: 3,
    //             spRate: 0,
    //             mobileRate: 1.2,
    //             customRate: 1,
    //             year: '2022',
    //             groupId: '3',
    //             alias: '磊山高峰會',
    //             isDisplay: '0',
    //             hasOrgMode: true,
    //             isCeoVisible: true,
    //             remoteRate: '1.00000',
    //             isVisible: '1',
    //             stylers: null,
    //             scoreFybRules: null
    //         },
    //         {
    //             id: 2,
    //             name: '2022年金起四超人',
    //             scoreTitle: '單位',
    //             dateStart: '2022-06-01 00:00:00.000',
    //             dateEnd: '2022-08-02 00:00:00.000',
    //             scoreFybPerUnit: 99999999,
    //             leaderScoreFybPerUnit: 99999999,
    //             commitDateEnd: '2022-10-01 00:00:00.000',
    //             useScoreTitle: false,
    //             baseRate: 1,
    //             fwRate: 1,
    //             spRate: 0,
    //             mobileRate: 1,
    //             customRate: 1,
    //             year: '2022',
    //             groupId: '1',
    //             alias: '金起四超人',
    //             isDisplay: '0',
    //             hasOrgMode: true,
    //             isCeoVisible: true,
    //             remoteRate: '1.00000',
    //             isVisible: '1',
    //             stylers: null,
    //             scoreFybRules: null
    //         },
    //         {
    //             id: 1,
    //             name: '2022寰宇菁英會',
    //             scoreTitle: '星星',
    //             dateStart: '2022-03-01 00:00:00.000',
    //             dateEnd: '2022-06-01 00:00:00.000',
    //             scoreFybPerUnit: 300000,
    //             leaderScoreFybPerUnit: 300000,
    //             commitDateEnd: '2022-08-01 00:00:00.000',
    //             useScoreTitle: true,
    //             baseRate: 1,
    //             fwRate: 3,
    //             spRate: 0,
    //             mobileRate: 1.2,
    //             customRate: 1,
    //             year: '2022',
    //             groupId: '2',
    //             alias: '寰宇菁英會',
    //             isDisplay: '0',
    //             hasOrgMode: true,
    //             isCeoVisible: true,
    //             remoteRate: '1.00000',
    //             isVisible: '1',
    //             stylers: null,
    //             scoreFybRules: null
    //         }
    //     ]
    // }
}

const postBackstageUpdateProductsAPI = () => {
    return axios.post(`${API_HOST}/backstage/products/categories/update`, {})
}
const postBackstageGenerateReportAPI = (contestID) => {
    return axios.post(
        `${API_HOST}/backstage/contests/reports/company/generate`,
        contestID
    )
}
const getBackstageReportInfosAPI = () => {
    return axios.get(`${API_HOST}/backstage/contests/reports/infos`)
}
const getBackstageDownloadReportAPI = (latestReportID) => {
    return axios.get(
        `${API_HOST}/backstage/contests/reports/company/download/${latestReportID}`
    )
}
const getBackstagePerformanceReportInfosAPI = () => {
    return axios.get(`${API_HOST}/backstage/performanceCommits/reports/infos`)
}
const getBackstageDownloadPerformanceReportAPI = (id) => {
    return axios.get(
        `${API_HOST}/backstage/performanceCommits/reports/company/download/${id}`
    )
}
const postBackstageGeneratePerformanceReportAPI = (payload) => {
    return axios.post(
        `${API_HOST}/backstage/performanceCommits/reports/company/generate`,
        payload
    )
}

const postBackstageDownloadProductAPI = (payload) => {
    return axios.post(`${API_HOST}/backstage/products/files/download`, payload)
}

const postBackstageUploadProductAPI = (formData) => {
    return axios.post(`${API_HOST}/backstage/products/files/upload`, formData)
}

/************************************
 * THESE ARE PRODUCT DB APIS BELOW
 ************************************/

const getProductReceiveAPI = (payload) => {
    return axios.post(`${API_HOST}/product/bonusPredict`, payload)
}

const getProductContractAPI = (payload) => {
    return axios.post(`${API_HOST}/product/embeddedBonus`, payload)
}

const getProductSupplierListAPI = () => {
    return axios.get(`${API_HOST}/product/supplierList`)
}

const getProductSummarySuppliersAPI = () => {
    return axios.get(`${API_HOST}/product/summary/suppliers`)
}

const postProductSummaryProductsAPI = (payload) => {
    return axios.post(`${API_HOST}/product/summary/suppliers/products`, payload)
}

const postProductSummaryAPI = (payload) => {
    return axios.post(`${API_HOST}/product/summary/list`, payload)
}
const postProductListAPI = (payload) => {
    return axios.post(`${API_HOST}/product/productList`, payload)
}
const postProductTimeInfoAPI = (payload) => {
    return axios.post(`${API_HOST}/product/productTimeInfo`, payload)
}
const postProductInfoAPI = (payload) => {
    return axios.post(`${API_HOST}/product/productInfo`, payload)
}

/************************************
 *
 ************************************/
axios.interceptors.request.use(
    function (request) {
        const formDataRoutes = [
            `${API_HOST}/insuranceService/uploadExcel`,
            `${API_HOST}/backstage/products/files/upload`
        ]
        const isUsingFormData = formDataRoutes.includes(request.url)

        if (request.data) {
            if (!isUsingFormData)
                request.data = transformOutputType(request.data)
        }
        if (request.url !== '/_sso/token') {
            request.headers = Object.assign({}, request.headers, headers)
        }
        return request
    },
    function (error) {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        axios.defaults.headers.common['retryCount'] = 0
        return response
    },
    async (error) => {
        const retryMaxTime = 2
        const status = error.response ? error.response.status : null
        const msg = error.response ? error.response.data.msg : ''
        console.log('error: ', error.response)
        const route = router.currentRoute
        const path = route.value.path
        if (
            status === 401 &&
            (msg.includes('expired') || msg.includes('unauthorized'))
        ) {
            // Go refresh token (will loop if refreshToken returns 401)
            try {
                const refreshRes = await refreshTokenAPI()
                const tokenType = refreshRes.data.token_type
                const accessToken = refreshRes.data.access_token
                const refreshToken = refreshRes.data.refresh_token
                const authorization = `${tokenType} ${accessToken}`
                store.commit('set', {
                    token: authorization,
                    refreshToken: refreshToken
                })
                error.config.headers['Authorization'] = authorization
                error.config.baseURL = undefined
                // retry
                let retryCount = error.config.headers['retryCount'] || 0
                if (retryCount < retryMaxTime) {
                    retryCount += 1
                    error.config.headers['retryCount'] = retryCount
                    return axios.request(error.config)
                } else {
                    return Promise.reject(error)
                }
            } catch (refreshError) {
                // Would be nice to catch an error here, which would work, if the interceptor is omitted
                console.log(refreshError)
                // Go logout
                store.commit('set', initialVuexStore.state)
                store.commit('set', { lastPagePath: path })
                window.location = '/login'
            }
        } else if (status == 400) {
            // already known error flows
            return Promise.reject(error)
        } else {
            let retryCount = axios.defaults.headers.common['retryCount'] || 0
            if (retryCount < retryMaxTime) {
                retryCount += 1
                axios.defaults.headers.common['retryCount'] = retryCount
                return axios.request(error.config)
            } else {
                return Promise.reject(error)
            }
        }
    }
)

export {
    getTokenAPI,
    getUserInfoAPI,
    getAccountCodesAPI,
    getCommissionMTypeAPI,
    getCommissionCodesAPI,
    getCommissionHistoryAPI,
    getCommissionOrgHistoryAPI,
    getCommissionPreClosingAPI,
    closingCommissionAPI,
    openingCommissionAPI,
    setCommissionVisibleAPI,
    downloadSalesZipAPI,
    downloadAdminZipAPI,
    sendZipPasswordToAdminAPI,
    syncPksListAPI,
    generateCommissionAPI,
    syncPksAPI,
    canSyncPksAPI,
    getOrganizationListAPI,
    getOrgChangeListAPI,
    getEditDropdownCodesAPI,
    editOrgAPI,
    getPerformanceCodesAPI,
    getPerformanceListAPI,
    getPerformancePersonalDetailsAPI,
    getReportAnnualCodesAPI,
    getReportIntervalCodesAPI,
    getReportListAnnualBranchAPI,
    getReportListAnnualPersonalAPI,
    getReportListDetailAPI,
    getReportListDailyAPI,
    getReportContestCodesAPI,
    getReportContestPersonalAPI,
    getReportContestOrgAPI,
    getReportListIntervalAPI,
    getPopularProductsAPI,
    getTiaInsuranceListAPI,
    getTiaInsuranceDetailAPI,
    editTiaInsuranceDetailAPI,
    completeTiaInsuranceNoticeAPI,
    getTiaCustomerListAPI,
    getTiaCustomerDetailListAPI,
    getCustomerSearchCodesAPI,
    getTiaNotificationListAPI,
    getNotificationCodesAPI,
    getTiaSupplierSyncListAPI,
    getTiaInsuranceCommissionDetailAPI,
    getTiaInsuranceSettingAPI,
    editTiaInsuranceSettingsAPI,
    getPageColumnConfigAPI,
    savePageColumnConfigAPI,
    downloadPropertyExcelAPI,
    uploadPropertyExcelAPI,
    getDashboardLicenseAPI,
    getDashboardNotificationAPI,
    getDashboardCustomerAgeAPI,
    getDashboardAwardQuickLinkAPI,
    getDashboardPersonalPerformanceAPI,
    getDashboardOrgPerformanceAPI,
    getDashboardAnnouncementAPI,
    getDashboardTopTenProductionsAPI,
    getDashboardDiscontinuedProdAPI,
    getDashboardContestAPI,
    getDashboardOrganizationManpowerAPI,
    getManpowerCodesAPI,
    getManpowerListAPI,
    getKMFileAPI,
    getBackstageProductFilesInfoAPI,
    postBackstageUpdateProductsAPI,
    postBackstageGenerateReportAPI,
    getBackstageReportInfosAPI,
    getBackstageDownloadReportAPI,
    postBackstageDownloadProductAPI,
    getBackstagePerformanceReportInfosAPI,
    getBackstageDownloadPerformanceReportAPI,
    postBackstageGeneratePerformanceReportAPI,
    postBackstageUploadProductAPI,
    refreshTokenAPI,
    putCustomerInfoApi,
    getBackstageContestInfoAPI,
    getAwardsCodesAPI,
    getAwardsDetailAPI,
    getAwardsHgaPersonalAPI,
    getAwardsHgaOrgAPI,
    getProductReceiveAPI,
    getProductContractAPI,
    getProductSummarySuppliersAPI,
    postProductSummaryProductsAPI,
    postProductSummaryAPI,
    getProductSupplierListAPI,
    postProductListAPI,
    postProductTimeInfoAPI,
    postProductInfoAPI,
    getAwardsLgaPersonalAPI,
    getAwardsLgaOrgAPI,
    getAwardsMdrtPersonalAPI,
    getAwardsMdrtOrgAPI,
    postUserActionAPI,
    getAwardsIdaPersonalAPI,
    getAwardsIdaOrgAPI,
    getAwardsGoldPersonalAPI,
    getAwardsGoldOrgAPI,
    getAwardsApfPersonalAPI,
    getAwardsApfOrgAPI
}
